// extracted by mini-css-extract-plugin
export var bannerWrapper = "privacy-module--bannerWrapper--46Hgh";
export var privacyContent = "privacy-module--privacyContent--zM2KC";
export var privacyUl = "privacy-module--privacyUl--yH5Qz";
export var privacyUlLiUl = "privacy-module--privacyUlLiUl--h-nqD";
export var section = "privacy-module--section--eu9ig";
export var textbold = "privacy-module--textbold--yZFJ5";
export var textboldu = "privacy-module--textboldu--hJsCy";
export var textlink = "privacy-module--textlink--BKm6S";
export var textu = "privacy-module--textu--oOeVU";
export var toclink = "privacy-module--toclink--BGLU9";
export var wrapper = "privacy-module--wrapper--UTGAo";